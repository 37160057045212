import {InsuranceInfo} from './interface';
import React, {useRef} from 'react';
import app from '../../app/app';
import CampaingCodeExitView from '../overlay/campaign-code-exit';

import * as Constants from '../../config/constants';
import {FormattedMessage, useIntl} from 'react-intl';
import {getIntl} from '../../intl';

type InsuranceProps = {
    insurancesInfo: InsuranceInfo[] | null,
    moodImage: string,
    isB2B: boolean,
    hideEdit?: boolean
}

/**
 * View: Ihre Reiseversicherung
 *
 * @param props InsuranceProps
 */
export default function InsuranceView (props: InsuranceProps) {
    const {formatMessage} = useIntl();
    const {
        insurancesInfo,
        moodImage,
        isB2B,
        hideEdit
    } = props;
    const {
        display,
        items,
        withoutText
    } = prepareInsurances(insurancesInfo);

    const trackEvent = () => {
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: 'Versicherung',
                ga4clickLabel: 'content.icon.uebersicht-reiseversicherung'
            });
        }
        if (app.router) {
            if (app?.apiSession?.bookingStatus?.campaignCodes.hasEnteredCode()) {
                onCampaignCodeExitOverlay();
            } else {
                app.router.redirect('insurance', true, false);
            }
        }
        return false;
    };

    // TUICUNIT-3170
    const gotToTarget = () => {
        if (app.router) {
            app.router.redirect('insurance', true, false);
        }
    };

    const overlayRef = useRef();
    const onCampaignCodeExitOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.show();
        }
    };

    const confirmData = {
        confirmCallback: gotToTarget,
        closeOnBgClick: true
    };

    return (
        <>
            {display ?
                <section className="check-section">
                    <div className="row">
                        {!isB2B ?
                            <div className="col-sm-4 hidden-xs-down">
                                <div className="check-image">
                                    <img alt={formatMessage({
                                        id: 'general.insurance.travel'
                                    })} src={moodImage} width="500" loading="lazy"/>
                                </div>
                            </div>
                            : ''}
                        <div className="col-xs-12 col-sm-8 text-wrapper">
                            <div className="section-headline">
                                <FormattedMessage id="components.overview.insurance.headline"/>
                            </div>
                            {items ?
                                <>
                                    {items.map((item: InsuranceInfo, key: number) => {
                                        return (
                                            <span key={key}>
                                                {key !== 0 ? <br/> : ''}
                                                <div className="bold">{item.insurance}, {item.displayName} </div>
                                                {item.shortdescription} <br/>
                                                <div className="participant-spacer"></div>
                                                {item.participantNameArray.map((pItem: string, pKey: number) => {
                                                    return (
                                                        <div key={pKey}>{pItem}</div>
                                                    );
                                                })}
                                            </span>
                                        );
                                    })}
                                </>
                                : ''}
                        </div>
                    </div>
                    <span className={`button button-secondary edit-link ${hideEdit ? 'hidden' : ''}`} aria-label={formatMessage({
                        id: 'components.overview.insurance.back'
                    })} onClick={trackEvent}>
                        <span className="icon-ic-edit"></span>
                    </span>
                    <CampaingCodeExitView data={confirmData} ref={overlayRef}></CampaingCodeExitView>
                </section>
                : ''}
            {!display && isB2B ?
                <section className="check-section">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 text-wrapper">
                            <div className="section-headline">
                                <FormattedMessage id="components.overview.insurance.headline"/>
                            </div>
                            <div>
                                {withoutText}
                            </div>
                        </div>
                    </div>
                    <span className={`button button-secondary edit-link ${hideEdit ? 'hidden' : ''}`} aria-label={formatMessage({
                        id: 'components.overview.insurance.back'
                    })} onClick={trackEvent}>
                        <span className="icon-ic-edit"></span>
                    </span>
                    <CampaingCodeExitView data={confirmData} ref={overlayRef}></CampaingCodeExitView>
                </section>
                : ''}
        </>
    );
}

/**
 * filter Insurance Date
 **/
const prepareInsurances = (raw: InsuranceInfo[] | null) => {
    let display = false;
    let items = null;
    let withoutText = '';

    if (raw) {
        if (raw.length === 1 && raw[0].memberType === Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
            display = false;
            withoutText = raw[0].insurance;
        } else {
            items = raw.reduce((list: InsuranceInfo[], item: InsuranceInfo) => {
                display = true;
                if (item.memberType !== Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
                    list.push({
                        ...item,
                        'shortdescription': mapPolicyTypeToContentApiShortDescription(item.policyType)
                    });
                }
                return list;
            }, []);
        }
    }

    return {
        display,
        items,
        withoutText
    };
};


/**
 *  find ContentAPI shortdescription by IBE-API policyType
 **/
const mapPolicyTypeToContentApiShortDescription = (type: string) => {
    let value = '';
    const contentApiText: any = app?.contentApi?.messages?.prepareMessages('insurances');
    const {formatMessage} = getIntl();
    const mapping: { [name: string]: string } = {
        // this is maybe the real mapping
        [formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_ADDITION})]: Constants.CONTENTAPI_INSURANCES_ADDITION,
        [formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_TRAVELCANCEL})]: Constants.CONTENTAPI_INSURANCES_TRAVELCANCELLATION,
        [formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_PREMIUM})]: Constants.CONTENTAPI_INSURANCES_PREMIUM
    };

    if (contentApiText && contentApiText[mapping[type]] && contentApiText[mapping[type]].shortdescription) {
        value = contentApiText[mapping[type]].shortdescription;
    }
    return value;
};
