import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/confirm-trustpilot/layout.hbs';
import {getLocale} from '../../../intl';


export default class ConfirmTrustpilotView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'confirm-trustpilot',
            templateWebpack,
            className: 'confirm-trustpilot',
            ...options
        });
    }

    templateContext () {
        return {
            language: getLocale() === 'en' ? 'en-GB' : 'de-DE'
        };
    }
}
