export const addToCart = (productId, value) => {
    if (typeof window.DY === 'undefined') {
        const int = setInterval(() => {
            if (typeof window.DY !== 'undefined') {
                clearInterval(int);
                addToCart(productId, value);
            }
        }, 100);
    } else {
        if (typeof window.DY !== 'undefined') {
            console.log('DY addToCart', productId, value);
            window.DY.API('event', {
                name: 'IBE - Add to Cart',
                properties: {
                    dyType: 'add-to-cart-v1',
                    value: value ?? 0,
                    productId,
                    quantity: 1
                }
            });
        }
    }
};
