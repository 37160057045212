import BaseItem from '../../../base/view/item';
import app from '../../../app/app';
import NavigationUtil from '../../../util/navigation';
import templateWebpack from '../../../../tpl/item/booking-pager/layout.hbs';
import * as Constants from '../../../config/constants';
import Config from '../../../config/config';
import {getIntl} from '../../../intl';
import OnlineRiskStepModel from '../../../entities/netmatch-api/model/onlineRiskStep';


export default class BookingPagerView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'booking-pager',
            templateWebpack,
            className: 'row',
            ...options
        });

        this.listenTo(app, 'stepsData:sync', this.render);
        // this.listenTo(app.apiSession.steps, 'sync', this.render);
        this.listenTo(app, 'bookingPageStatus:change', this.onBookingPageStatusChange);
        if (options.position === 'sticky') {
            this.stickyIsVisible = false;
            // This -> " || this.model.allCompleted() === true" because of the fucking insurancesStep
            let setTrigger = false;
            if (this.model.allStepsHaveValidInputs() === true || this.model.allCompleted() === true) {
                setTrigger = true;
            }
            // edge case: sometimes is the Cabin Page initialize ok, but if you change something, Api says all is broken
            if (this.model.id === Constants.PAGE_CABIN && this.model.allCompleted() === false) {
                setTrigger = false;
            }
            // special handling insurances TUICIBE-554: as there is noo access to the insurance selector model here,
            // we disable the pager and trigger a 'step:did:change' event on the insurance page
            if (this.model.id === Constants.PAGE_INSURANCE) {
                setTrigger = false;
            }

            if (setTrigger) {
                this.triggerStickyButton(true, Config.transition.stickyCTA.normal);
            }
        }
    }

    events () {
        return {
            'click .js-tracking-back': 'onClickTracking'
        };
    }

    ui () {
        return {
            'nextButton': '.button-next-page',
            'previousButton': '.button-previous-page'
        };
    }


    /**
     * View triggers shortcuts invoking triggerMethod for ui events
     */
    triggers () {
        return {
            'click @ui.nextButton': 'pager:next:page'
        };
    }


    onBookingPageStatusChange (state) {
        if (state === true) {
            if (this.$el.find('.button-next-page').hasClass('disabled') === true) {
                this.$el.find('.button-next-page').removeClass('disabled');
                this.$el.find('.js-sticky').removeClass('ne');
            }
        } else {
            if (this.$el.find('.button-next-page').hasClass('disabled') === false) {
                this.$el.find('.button-next-page').addClass('disabled');
                this.$el.find('.js-sticky').addClass('ne');
            }
        }
        if (this.getOption('position') === 'sticky') {
            this.triggerStickyButton(state, Config.transition.stickyCTA.fast);
        }
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const nextPage = this.model.getNextApplicablePage();
        const previousPage = this.model.getPreviousApplicablePage();
        const ctaMessages = app.contentApi.messages.prepareMessages('ctatexts');
        let hideBackButton = !!this.getOption('hideBackButton');
        let ctaMessage = '';


        // In case insurance gets hidden due to ch/uk invoice country we have to ask this way specifically
        if (nextPage.id === Constants.PAGE_OVERVIEW) {
            const insuranceModel = this.model.collection.get(Constants.PAGE_INSURANCE);
            if (insuranceModel && insuranceModel.get('ctaMessagesKey')) {
                ctaMessage = ctaMessages[insuranceModel.get('ctaMessagesKey')];
            }
        } else if (this.model.get('ctaMessagesKey')) {
            ctaMessage = ctaMessages[this.model.get('ctaMessagesKey')];
        }
        if (this.model.get('hide-back-button')) {
            hideBackButton = this.model.get('hide-back-button');
        }

        const defaultBackText = getIntl().formatMessage({
            id: 'tpl.item.header.layout.tripdetails'
        });
        let previousPageText = previousPage
            ? previousPage.get('button-back-title') || getIntl().formatMessage({
                id: 'components.overlay.index.back-page-title'
            }, {
                title: previousPage.get('page-title')
            })
            : getIntl().formatMessage({
                id: 'components.overlay.index.back-page-title'
            }, {
                title: defaultBackText
            });
        if (app.apiSession.bookingStatus.agency.isB2B()) {
            previousPageText = getIntl().formatMessage({
                id: 'components.overlay.index.back-infonet'
            });
        }

        // TCEC-148 hide back button when online risk was submitted
        if (!hideBackButton && this.model.id === Constants.PAGE_OVERVIEW) {
            const onlineRiskStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_ONLINE_RISK);
            const onlineRiskStepAsserted = onlineRiskStep ? new OnlineRiskStepModel(onlineRiskStep.attributes) : null;
            hideBackButton = onlineRiskStepAsserted.isPending() === false;
        }

        const context = {
            hideBackButton,
            previousPage: {
                text: previousPageText,
                url: previousPage ? NavigationUtil.normalizePath(previousPage.get('route')) : app.ibeController.appBackLink()
            },
            top: false,
            bottom: false,
            sticky: false
        };
        // console.log('%c allStepsHaveValidInputs ', 'background: #222; color: #ffff00', this.model.allStepsHaveValidInputs());
        // console.log('%c allCompleted ', 'background: #222; color: #ffff00', this.model.allCompleted());

        let buttonDisabled = true;
        if (this.getOption('autoEnable')) {
            buttonDisabled = !this.model.isDone();
            // if (this.model.allStepsHaveValidInputs() === true) {
            if (this.model.allStepsHaveValidInputs() === true || this.model.allCompleted() === true) {
                buttonDisabled = false;
            }
            // edge case: sometimes is the Cabin Page initialize ok, but if you change something, Api says all is broken
            if (this.model.id === Constants.PAGE_CABIN && this.model.allCompleted() === false) {
                buttonDisabled = true;
            }
        }

        if (nextPage) {
            context.nextPage = {
                text: nextPage.get('button-title') || getIntl().formatMessage({
                    id: 'components.overlay.index.continue-page-title'
                }, {
                    title: nextPage.get('page-title')
                }),
                disabled: buttonDisabled
            };
        }
        // console.log('%c context ', 'background: #222; color: #ffff00', context);
        if (this.getOption('position') === 'bottom') {
            context.bottom = true;
        }
        if (this.getOption('position') === 'top') {
            context.top = true;
        }

        if (this.getOption('position') === 'sticky') {
            context.sticky = true;
            context.ctaMessage = ctaMessage;

            // edge case: TUICIBE-530 Deck blocked: nextPage are ready, but the sticky state is false
            if (this.model.id === Constants.PAGE_CABIN && nextPage && buttonDisabled === false && this.stickyIsVisible === false) {
                this.onBookingPageStatusChange(true);
            }

            // case: TUICUNIT-1469 roundTrip and not the same Airport available, sticky state is false
            if (this.model.id === Constants.PAGE_TRAVEL && nextPage && buttonDisabled === false && this.stickyIsVisible === false) {
                this.onBookingPageStatusChange(true);
            }

            if (this.stickyIsVisible === true) {
                context.stickyShow = true;
            }
        }

        return context;
    }

    triggerStickyButton (state, transition) {
        if (state === true) {
            setTimeout(function () {
                let trigger = false;

                // This -> " || this.model.allCompleted() === true" because of the fucking insurancesStep
                if (this.model.allStepsHaveValidInputs() === true || this.model.allCompleted() === true) {
                    trigger = true;
                }

                // and new Case Page TRAVEL they is ready if both are true
                // and new Case Page Cabin they is ready if both are true because TUICIBE-530: Deck blocked
                if (this.model.id === Constants.PAGE_TRAVEL || this.model.id === Constants.PAGE_CABIN) {
                    if (this.model.allStepsHaveValidInputs() === true && this.model.allCompleted() === true) {
                        trigger = true;
                    } else {
                        trigger = false;
                    }
                }
                // console.log(`${transition}  ${this.model.id} ${this.model.allStepsHaveValidInputs()} ${this.model.allCompleted()} => ${trigger} (${this.model.isDone()}) ${this.model.pageStatus}`);

                if (trigger) {
                    this.$el.find('.js-sticky').addClass('show');
                    // TUICIBE-491: Work'a'round IE Edge !!!!!!!!
                    this.$el.find('.js-sticky').css({overflow: 'hidden'});
                    this.stickyIsVisible = state;
                }
            }.bind(this), transition);
        } else {
            setTimeout(function () {
                this.$el.find('.js-sticky').removeClass('show');
                this.stickyIsVisible = state;
            }.bind(this), Config.transition.stickyCTA.out);
        }
    }

    onClickTracking () {
        let label = null;
        switch (app.router.getCurrentFragment()) {
            case Constants.PAGE_CABIN:
                label = 'Zur Reisedetailseite';
                break;
            default:
                break;
        }
        if (label) {
            if (app && app.trackController) {
                app.trackController.eventTracking({
                    action: 'Kabinenwahl_CTA',
                    label: label,
                    ga4clickLabel: `content.button.${label}`
                });
            }
        }
    }
}
