import React from 'react';
import {createRoot} from 'react-dom/client';
import InsuranceCarousel from '../../../components/insurance/insurance-carousel-js';
import OverlayCancelView from '../../../components/insurance/overlay-cancel';

import app from '../../../app/app';
import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/insurance-overview/layout.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';
import {getIntl} from '../../../intl';

const {formatMessage} = getIntl();

const membersTypeLabels = {
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL]: formatMessage({id: Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL_LABEL}),
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE]: formatMessage({id: Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE_LABEL}),
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY]: formatMessage({id: Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY_LABEL})
};


export default class InsuranceOverviewView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'insurance-overview',
            templateWebpack,
            ...options
        });
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            insurances: this.getInsuranceDetails(),
            infoTexts: this.getContentApiData().infoTexts
        };
    }

    /**
     * View events
     */
    events () {
        const contentApiData = this.getContentApiData();
        return {
            'click .js-button-details': (e) => {
                const $element = this.$(e.currentTarget);
                const typeId = $element.data('type');
                app.trackController.eventTracking({
                    action: 'Versicherung',
                    label: `${contentApiData[typeId].name}|Details`,
                    ga4clickLabel: `content.button.reiseversicherung-${this.cleanText(contentApiData[typeId].name)}-details`
                });
            }
        };
    }

    /**
     * remove all html tags
     *
     * @param {string} text
     * @returns clean String
     */
    cleanText (text) {
        let cText = text || '';
        cText = cText.replace(/(<br>)/gi, ' ');
        cText = cText.replace(/(<([^>]+)>)/gi, '');
        cText = cText.replace(/([\n\t])/gi, ' ');
        cText = cText.split('  ').join(' ').trim();
        return cText;
    }


    onRender () {
        // react-region-insurance-overview-cancelation
        if (this.$el.find('.rrio-cancel')[0]) {
            const container = this.$el.find('.rrio-cancel')[0];
            const root = createRoot(container);
            renderReact(root, <OverlayCancelView/>);
        }
    }


    onAttach () {
        if (this.$el.find('.insurance-swiper-region')[0]) {
            const container = this.$el.find('.insurance-swiper-region')[0];
            const root = createRoot(container);
            renderReact(root, <InsuranceCarousel insurances={this.getInsuranceDetails()}/>);
        }
    }

    /**
     * Get the details for the insurance overview carousel
     *
     * @return {array} A list of insurance details
     */
    getInsuranceDetails () {
        // caution: redefine to grant correct order of insurance types here already
        // TUICROLLOUTS-464: NetMatch has a new INSURANCE Name, but not YoursTruly, so we have to map "basic" with "ADDITION"

        const {formatMessage} = getIntl();

        const insuranceTypeMapping = {
            premium: formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_PREMIUM}),
            travelCancelation: formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_TRAVELCANCEL}),
            basic: formatMessage({id: Constants.NETMATCH_INSURANCE_KEY_ADDITION})
        };

        const contentApiData = this.getContentApiData();
        return Object.keys(insuranceTypeMapping).reduce((items, contentApiType) => {
            const ibeApiType = insuranceTypeMapping[contentApiType];
            const ibeApiDetails = this.getIbeApiDetails(ibeApiType);
            if (ibeApiDetails) {
                items.push({
                    typeId: contentApiType,
                    ...ibeApiDetails,
                    ...contentApiData[contentApiType],
                    isTypeBasic: ibeApiType === insuranceTypeMapping.basic,
                    isTypeTravelCancellation: ibeApiType === insuranceTypeMapping.travelCancelation,
                    isTypePremium: ibeApiType === insuranceTypeMapping.premium
                });
            }
            return items;
        }, []);
    }


    /**
     * Returns the content api data for the insurance page
     *
     * @return {object}
     */
    getContentApiData () {
        const contentApiData = app.contentApi.messages.prepareMessages('insurances');
        if (!contentApiData) {
            return {};
        }
        return contentApiData;
    }


    /**
     * Returns the ibe data for to the given insurance type
     *
     * @param  {string} type the insurance type
     * @return {Object}
     */
    getIbeApiDetails (type) {
        const ibeAttributes = this.model.get('netMatchAttributes');
        if (!ibeAttributes) {
            return {};
        }
        const ibeDetails = ibeAttributes.find(ibeAttribute => ibeAttribute.key === type);
        if (!ibeDetails) {
            return {};
        }
        return {
            ...ibeDetails,
            membersTypeLabel: membersTypeLabels[ibeDetails.membersTypeName],
            retention: {
                required: ibeDetails.retention,
                displayText: ibeDetails.retention ? Constants.INFO_INSURANCE_RETENTION_REQUIRED_TEXT : Constants.INFO_INSURANCE_RETENTION_NOT_REQUIRED_TEXT
            }
        };
    }
}
