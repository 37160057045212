import {View} from 'backbone.marionette';

import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/navigation/navigation-item.hbs';

import NavigationUtil from '../../../util/navigation';
import * as Constants from '../../../config/constants';

export default class NavigationView extends View {
    constructor (options = {}) {
        options = Object.assign({
            tagName: 'li',
            template: templateWebpack
        }, options);

        super(options);
    }

    events () {
        return {
            'click .js-tracking-navi': (e) => {
                const label = e.currentTarget.dataset.totrack;
                if (app && app.trackController && label) {
                    app.trackController.eventTracking({
                        ga4clickLabel: `navi.button.${label}`
                    });
                }
            }
        };
    }

    className () {
        let className = this.model.status;

        if (this.model.getRoutes().indexOf(app.router.getCurrentFragment()) !== -1) {
            className += ' active';
        } else {
            if (app.router.getCurrentFragment() === Constants.PAGE_CONFIRM) {
                className += ' finished';
            }
        }

        // from the Payment is Navigation not allowed
        if (app.router.getCurrentFragment() === Constants.PAGE_PAYMENT && this.model.status === Constants.BOOKING_PAGE_STATUS_DONE) {
            className += ' finished';
        }

        if (this.model.id === Constants.PAGE_INSURANCE && this.model.isNotApplicable()) {
            className += ' hidden';
        }

        return className;
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const icon = this.model.allCompleted() ? this.model.get('menu-icon-done') : this.model.get('menu-icon');
        return {
            title: this.model.get('menu-title') || this.model.get('page-title'),
            icon,
            url: NavigationUtil.getPath(this.model.get('route')),
            tracking: this.model.get('tracking') || {}
        };
    }
}
